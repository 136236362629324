@import url(https://fonts.googleapis.com/css?family=Abel|Aguafina+Script|Artifika|Athiti|Condiment|Dosis|Droid+Serif|Farsan|Gurajada|Josefin+Sans|Lato|Lora|Merriweather|Noto+Serif|Open+Sans+Condensed:300|Playfair+Display|Rasa|Sahitya|Share+Tech|Text+Me+One|Titillium+Web);
/****************CONFIGURACION-BASICA************************/
html,body,#root{
    height: 100%;
    width: 100%;
    margin:0;
    padding:0;
}
.swal2-container {
    z-index: 30000!important;
}

.swal2-container {
    display: flex;
    position: fixed;
    z-index: 30000!important;
}

body {
    /* background: linear-gradient(135deg, #6ec575 0, #3b8686 100%); */
    /* background-repeat: repeat;
    background: url("./componentesGenericos/assets/Img/Fondo.png") repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
    background-size: 150px;
  background-repeat: round;
  background-image:
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAAWJLR0QCZgt8ZAAAAFNJREFUWEft0SEKwDAQBdFtAyH3P2NddSEESmoqd/yKeebbgX9c97OjiPPfEowhxhBjiDHEGGIMMYYYkxm91YmZ6/UmZAwxhhhDjCHGEGOIMbmID7jEBwmtZHDDAAAAAElFTkSuQmCC);
}

.boton {
    margin: "10px";
    padding: "20px";
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


/****************ALERTAS************************/
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  .alert {
    border: 0;
    border-radius: 0;
    padding: 20px 15px;
    line-height: 20px;
  }

  .alert.alert-primary {
    background-color: #a72abd;
    color: #ffffff;
  }
  .alert.alert-primary a,
  .alert.alert-primary .alert-link {
    color: #ffffff;
  }
  .alert.alert-success {
    background-color: #55b559;
    color: #ffffff;
  }
  .alert.alert-success a,
  .alert.alert-success .alert-link {
    color: #ffffff;
  }
  .alert.alert-info {
    background-color: #00cae3;
    color: #ffffff;
  }
  .alert.alert-info a,
  .alert.alert-info .alert-link {
    color: #ffffff;
  }
  .alert.alert-warning {
    background-color: #ff9e0f;
    color: #ffffff;
  }
  .alert.alert-warning a,
  .alert.alert-warning .alert-link {
    color: #ffffff;
  }
  .alert.alert-danger {
    background-color: #f55145;
    color: #ffffff;
  }
  .alert.alert-danger a,
  .alert.alert-danger .alert-link {
    color: #ffffff;
  }
  .alert.alert-rose {
    background-color: #ea2c6d;
    color: #ffffff;
  }
  .alert.alert-rose a,
  .alert.alert-rose .alert-link {
    color: #ffffff;
  }
  .alert-info,
  .alert-danger,
  .alert-warning,
  .alert-success {
    color: #ffffff;
  }

  .alert-dismissible {
    padding-right: 4rem;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
/****************HELP************************/
  .w100{
      width: 100%!important;
  }

/******************CARUSEL****************************/

.carousel-root{
	height: 100%!important;
	max-width: 100%!important;
}
.carousel.carousel-slider{
	height: 100%!important;
    max-width: 100%!important;
}
.carousel .slider-wrapper{
	height: 100%!important;
	max-width: 100%!important;
}
.carousel .slider.animated{
	height: 100%!important;
	max-width: 100%!important;
}
.slide{
	height: 100%!important;
	max-width: 100%!important;
}
.carousel .slider-wrapper.axis-horizontal .slider{
    height: 100%!important;
	max-width: 100%!important;
}

.maximizaAlto{
    height: 100%!important;
    max-width: 100%!important;
    cursor: pointer;
}

.maximizaAltoImagen{
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.cardMovil{
    height: 100%;
    border: 2px solid rgba(0, 123, 255,0.33);
    border-radius: 9px;
}
.cardContent{
    height: 100%;
    width: 100%;
    padding: 0px;
}

.imagenContentMovil{
width: auto;
height: 100%;
max-Height:100px;
}

/*BOTON DE LOGIN*/


.MuiButton-contained.Mui-disabled{
background:white!important;
}
.MuiTableCell-body{
color: transparent;
}
.colorFila{
    color:red!important;
}
.colorNegro{
    color:black;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* fonts  */

#td-container {
    box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
    background: rgba(255, 255, 255, 0.9);
    text-align: left;
    border-radius: 1px;
    border: 2px 2px solid black;
    overflow: hidden;
    margin: 0em auto;
    position: relative;
    max-height: 1280px;
    height: 100%;
    max-width: 507px;
    width: 100%;
}

#td-container img {
    width: 100%;
    height: 100%;
}

.td-product-details {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    float:none
}
/* Nombre del paciente */
.td-information {
    position: absolute;
    top: 42%;
    left: 3%;
    /* width: auto;
    height: auto; */
    color: black;
    font-size: 23px;

}
/* Imagen del QR */
.td-product-qr {
    position: absolute;
    top: 2.7%;
    left: 5.5%;
    height: 22.9%;
    width: 22.9%;
    text-align: center;
    font-weight: bold;
    font-size: 5vw;
    color: white;
    font-family: arial;
}
/* Sin imagen */

#td-container {
    box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
    background: rgba(255, 255, 255, 0.9);
    text-align: left;
    border-radius: 1px;
    border: 2px 2px solid black;
    overflow: hidden;
    margin: 0em auto;
    position: relative;
    height: 100%;
    width: 100%;
}

.td-product-details {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    float:none
}
/* Imagen del QR - Sin imagen*/
.td-product-qr-2 {
    position: relative;
    top: 4%;
    left: 15%;
    height:auto;
    width: 70%;
    text-align: center;
    font-weight: bold;
    font-size: 5vw;
    color: white;
    font-family: arial;
}
/* Información del paciente - sin imagen*/
.td-information-2 {
    position: relative;
    top: 42%;
    /* left: 3%; */
    width: auto;
    height: auto;
    color: black;
    font-size: 23px;

}

@media (max-width: 1700px) and (max-height: 1200px){
    #td-container {
        box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
        background: rgba(255, 255, 255, 0.9);
        text-align: left;
        border-radius: 1px;
        border: 2px 2px solid black;
        overflow: hidden;
        margin: 0em auto;
        position: relative;
        max-height: 1200px;
        height: 100%;
        max-width: 600px;
        width: 27%;
    }

    #td-container img {
        width: 100%;
        height: 100%;
    }

    .td-product-details {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        float:none
    }
    /* Nombre del paciente */
    .td-information {
        position: absolute;
        top: 42.3%;
        left: 1%;
        width: auto;
        height: auto;
        color: black;
        font-size: 15px;
        text-align: center;
    }
    /* Imagen del QR */
    .td-product-qr {
        position: absolute;
        top: 2.7%;
        left: 5%;
        height: 23.5%;
        width: 23.5%;
        text-align: center;
        font-weight: bold;
        font-size: 5vw;
        color: white;
        font-family: arial;
    }
    }


@media (max-width: 1300px) and (max-height: 700px){
    #td-container {
        box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
        background: rgba(255, 255, 255, 0.9);
        text-align: left;
        border-radius: 1px;
        border: 2px 2px solid black;
        overflow: hidden;
        margin: 0em auto;
        position: relative;
        max-height: 1200px;
        height: 100%;
        max-width: 600px;
        width: 35%;
    }

    #td-container img {
        width: 100%;
        height: 100%;
    }

    .td-product-details {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        float:none
    }
    /* Nombre del paciente */
    .td-information {
        position: absolute;
        top: 42.3%;
        left: 2%;
        width: auto;
        height: auto;
        color: black;
        font-size: 15px;
    }
    /* Imagen del QR */
    .td-product-qr {
        position: absolute;
        top: 2.7%;
        left: 5%;
        height: 23.5%;
        width: 23.5%;
        text-align: center;
        font-weight: bold;
        font-size: 5vw;
        color: white;
        font-family: arial;
    }
    }


/* Basada para iPad Pro */
@media (max-width: 1024px) and (max-height: 1366px){
    #td-container {
        box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
        background: rgba(255, 255, 255, 0.9);
        text-align: left;
        border-radius: 1px;
        border: 2px 2px solid black;
        overflow: hidden;
        margin: 1em auto;
        position: relative;
        max-height: 1300px;
        height: 100%;
        max-width: 740px;
        width: 100%;
    }

    #td-container img {
        width: 100%;
        height: 100%;
    }

    .td-product-details {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        float:none
    }
    /* Nombre del paciente */
    .td-information {
        position: absolute;
        top: 42.5%;
        left: 2%;
        width: auto;
        height: auto;
        color: black;
        font-size: 28px;
    }
    /* Imagen del QR */
    .td-product-qr {
        position: absolute;
        top: 2.7%;
        left: 5.5%;
        height: 23%;
        width: 23%;
        text-align: center;
        font-weight: bold;
        font-size: 5vw;
        color: white;
        font-family: arial;
    }
    }

/* Basada para iPad */
@media (max-width: 800px) and (max-height: 1024px){
    #td-container {
        box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
        background: rgba(255, 255, 255, 0.9);
        text-align: left;
        border-radius: 1px;
        border: 2px 2px solid black;
        overflow: hidden;
        margin: 1em auto;
        position: relative;
        max-height: 1000px;
        height: 100%;
        max-width: 610px;
        width: 100%;
    }

    #td-container img {
        width: 100%;
        height: 100%;
    }

    .td-product-details {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        float:none
    }
    /* Nombre del paciente */
    .td-information {
        position: absolute;
        top: 42.5%;
        left: 2%;
        width: auto;
        height: auto;
        color: black;
        font-size: 20px;
    }
    /* Imagen del QR */
    .td-product-qr {
        position: absolute;
        top: 2.7%;
        left: 5.5%;
        height: 23%;
        width: 23%;
        text-align: center;
        font-weight: bold;
        font-size: 5vw;
        color: white;
        font-family: arial;
    }
    }

@media (max-width: 400px) and (max-height: 650px){
#td-container {
    box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
    background: rgba(255, 255, 255, 0.9);
    text-align: left;
    border-radius: 1px;
    border: 2px 2px solid black;
    overflow: hidden;
    margin: 0em auto;
    position: relative;
    max-height: 640px;
    height: 100%;
    max-width: 380px;
    width: 100%;
}

#td-container img {
    width: 100%;
    height: 100%;
}

.td-product-details {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    float:none
}
/* Nombre del paciente */
.td-information {
    position: absolute;
    top: 42.5%;
    left: 2%;
    width: auto;
    height: auto;
    color: black;
    font-size: 16px;
}
/* Imagen del QR */
.td-product-qr {
    position: absolute;
    top: 2.7%;
    left: 5.5%;
    height: 23%;
    width: 23%;
    text-align: center;
    font-weight: bold;
    font-size: 5vw;
    color: white;
    font-family: arial;
}
}

/* Basada para iPhone 4 */
@media (max-width: 321px) and (max-height: 480px){
    #td-container {
        box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
        background: rgba(255, 255, 255, 0.9);
        text-align: left;
        border-radius: 1px;
        border: 2px 2px solid black;
        overflow: hidden;
        margin: 0em auto;
        position: relative;
        max-height: 470px;
        height: 100%;
        max-width: 310px;
        width: 100%;
    }

    #td-container img {
        width: 100%;
        height: 100%;
    }

    .td-product-details {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        float:none
    }
    /* Nombre del paciente */
    .td-information {
        position: absolute;
        top: 42%;
        left: 2%;
        width: auto;
        height: auto;
        color: black;
        font-size: 12px;
    }
    /* Imagen del QR */
    .td-product-qr {
        position: absolute;
        top: 2.7%;
        left: 5.5%;
        height: 23%;
        width: 23%;
        text-align: center;
        font-weight: bold;
        font-size: 5vw;
        color: white;
        font-family: arial;
    }
}

/* Basada para Pixel 2/XL */
@media (max-width: 420px) and (max-height: 830px){
    #td-container {
        box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
        background: rgba(255, 255, 255, 0.9);
        text-align: left;
        border-radius: 1px;
        border: 2px 2px solid black;
        overflow: hidden;
        margin: 0em auto;
        position: relative;
        max-height: 700px;
        height: 100%;
        max-width: 400px;
        width: 100%;
    }

    #td-container img {
        width: 100%;
        height: 100%;
    }

    .td-product-details {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        float:none
    }
    /* Nombre del paciente */
    .td-information {
        position: absolute;
        top: 42.3%;
        left: 2%;
        width: auto;
        height: auto;
        color: black;
        font-size: 16px;
    }
    /* Imagen del QR */
    .td-product-qr {
        position: absolute;
        top: 2.7%;
        left: 5.5%;
        height: 23%;
        width: 23%;
        text-align: center;
        font-weight: bold;
        font-size: 5vw;
        color: white;
        font-family: arial;
    }
}


@-webkit-keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 204, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 0 14px rgba(0, 204, 255, 0);
  }
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 204, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 0 14px rgba(0, 204, 255, 0);
  }
}

.pulso{
    -webkit-animation: shadow-pulse 1s infinite;
            animation: shadow-pulse 1s infinite;
}


input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
  }

.ticket * {
    font-size: 15px;
    font-family: 'Times New Roman';
}


.ticket {
    display: none;
    /* width: 220px; */
    max-width: 100%;
}

/* div.cargando {
    display:none;
} */

.ticket img {
    max-width: 90px;
    min-width: 80px !important;
}

.ticket .logo{
    margin: 0 auto;
    text-align: center;
}

.ticket .contenedor{
    border-top-style: double;
    border-bottom-style: double;
    padding: 0px 0;
    margin: 10px 0;
    font-size: 16px;
}

.ticket .titulo{
    font-size: 17px;
}


.ticket .tablaproductos, .ticket .tablaproductos td,.ticket .tablaproductos th,.ticket .tablaproductos tr {
    border-top: 0px solid black;
    border-collapse: collapse;
    font-size: 8px;
}

.ticket .tablaproductos{
    margin:0 auto;
    text-align: left;
    width: 100%;
}

.ticket .tablaproductos th{
    font-size: 9px;
}

.ticket .tablaproductos td.cantidad, .ticket .tablaproductos th.cantidad {
    width: 14%;
    max-width: 14%;
    word-break: break-all;
    text-align:right;
}

.ticket td.codigo {
    width: 10%;
    max-width: 10%;
    text-align: left;
    padding-left: 3px;
    font-size: 9px;
}

.ticket th.codigo {
    width: 10%;
    max-width: 10%;
    text-align: left;
    padding-left: 3px;
}

.ticket td.producto,.ticket th.producto {
    width: 30%;
    max-width: 30%;
    text-align: left;
    padding-left: 3px;
}

.ticket td.precio {
    width: 20%;
    max-width: 20%;
    word-break: break-all;
     text-align:right;

}

.ticket th.precio {
    width: 20%;
    max-width: 20%;
    word-break: break-all;
     text-align:center;

}

.ticket td.totalimporte {
    width: 24%;
    max-width: 24%;
    word-break: break-all;
     text-align:right;
}

.ticket th.totalimporte {
    width: 24%;
    max-width: 24%;
    word-break: break-all;
     text-align:center;
}


.ticket td.espacio,.ticket th.espacio {
    width: 0%;
    max-width: 1%;
}

.ticket .ahorro{
    text-align: center;
    font-weight: lighter;
}

.ticket .totales{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
  justify-content:flex-end;
  font-size: 12px;
}

.ticket .tablaTotal{
    font-size: 12px;
}

.ticket .totaltxt{
    text-align: right;
    font-weight: bold;
    font-size: 12px;
}

.ticket .derecha{
    text-align: right;
    font-weight: bold;
    font-size: 12px;
}

.ticket .izquierda{
    text-align: left;
    font-weight: bold;
    font-size: 12px;
}

.ticket .total{
    text-align: left;
    font-weight: bold;
}

.ticket .centrado {
    text-align: center;
    align-content: center;
}

.ticket .pie{
    text-align: right;
    width: 98%;
}

.ticket .garantias{
    border-top: 0;
    width:100%;
    text-align: center;
}

@media print {
    .cargando,
    .cargando * {
        display: none !important;
    }

    .ticket{
        display: inherit !important;
        width:100%;
    }
}

@-webkit-keyframes rotacion{
    0%{
        transform: rotate(0deg);
        }
    25%{
        transform: rotate(90deg);
        }
    50%{
        transform: rotate(180deg);
        }
    75%{
        transform: rotate(270deg);
        }
    100%{
        transform: rotate(360deg);
        }
}

@keyframes rotacion{
    0%{
        transform: rotate(0deg);
        }
    25%{
        transform: rotate(90deg);
        }
    50%{
        transform: rotate(180deg);
        }
    75%{
        transform: rotate(270deg);
        }
    100%{
        transform: rotate(360deg);
        }
}

.carga{
    display: block;
    -webkit-animation: rotacion 4s infinite 0s linear;
            animation: rotacion 4s infinite 0s linear;
    width: 80px;
    height: 80px;

}

.generando{
    display: block;
    width: 100%;
}

.cargando{
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 60vh;
}

p {
    padding: 0px;
    margin: 0px;
  }

.ticket2 * {
    font-size: 15px;
    font-family: 'Times New Roman';
}


.ticket2 {
    display: none;
    /* width: 220px; */
    max-width: 100%;
}

/* .contenedor2 div.cargando2 {
    display:none;
} */

.ticket2 img {
    max-width: 90px;
    min-width: 80px !important;
}

.ticket2 .logo{
    margin: 0 auto;
    text-align: center;
}

.ticket2 .contenedor{
    border-top-style: double;
    border-bottom-style: double;
    padding: 0px 0;
    margin: 10px 0;
    font-size: 16px;
}

.ticket2 .contenedor2{
    border-top-style: double;
    border-bottom-style: double;
    padding: 0px 0;
    margin: 10px 0;
    font-size: 16px;
}

.ticket2 .titulo{
    font-size: 17px;
}


.ticket2 .tablaproductos, .ticket2 .tablaproductos td,.ticket2 .tablaproductos th,.ticket2 .tablaproductos tr {
    border-top: 0px solid black;
    border-collapse: collapse;
    font-size: 12px;
}

.ticket2 .tablaproductos{
    margin:0 auto;
    text-align: left;
    width: 100%;
}

.ticket2 .tablaproductos th{
    font-size: 9px;
}

.ticket2 .tablaproductos td.cantidad, .ticket2 .tablaproductos th.cantidad {
    width: 14%;
    max-width: 14%;
    word-break: break-all;
    text-align:right;
}

.ticket2 td.codigo {
    width: 10%;
    max-width: 10%;
    text-align: left;
    padding-left: 3px;
    font-size: 9px;
}

.ticket2 th.codigo {
    width: 10%;
    max-width: 10%;
    text-align: left;
    padding-left: 3px;
}

.ticket2 td.producto,.ticket2 th.producto {
    width: 30%;
    max-width: 30%;
    text-align: left;
    padding-left: 3px;
}

.ticket2 td.precio {
    width: 20%;
    max-width: 20%;
    word-break: break-all;
     text-align:right;

}

.ticket2 th.precio {
    width: 20%;
    max-width: 20%;
    word-break: break-all;
     text-align:center;

}

.ticket2 td.totalimporte {
    width: 24%;
    max-width: 24%;
    word-break: break-all;
     text-align:right;
}

.ticket2 th.totalimporte {
    width: 24%;
    max-width: 24%;
    word-break: break-all;
     text-align:center;
}


.ticket2 td.espacio,.ticket2 th.espacio {
    width: 0%;
    max-width: 1%;
}

.ticket2 .ahorro{
    text-align: center;
    font-weight: lighter;
}

.ticket2 .totales{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
  justify-content:flex-end;
  font-size: 12px;
}

.ticket2 .tablaTotal{
    font-size: 12px;
}

.ticket2 .totaltxt{
    text-align: right;
    font-weight: bold;
    font-size: 12px;
}

.ticket2 .derecha{
    text-align: right;
    font-weight: bold;
    font-size: 12px;
}

.ticket2 .izquierda{
    text-align: left;
    font-weight: bold;
    font-size: 12px;
}

.ticket2 .total{
    text-align: left;
    font-weight: bold;
}

.ticket2 .centrado {
    text-align: center;
    align-content: center;
}

.ticket2 .pie{
    text-align: right;
    width: 98%;
}

.contenedor2 .ticket2 .garantias{
    border-top: 0;
    width:100%;
    text-align: center;
}

@media print {
    .contenedor2 .cargando2,
    .contenedor2 .cargando2 * {
        display: none !important;
    }

    .contenedor2 .ticket2{
        display: inherit !important;
        width:100%;
    }


    .noImprimir {
        display: none !important;
    }
}

@-webkit-keyframes rotacion{
    0%{
        transform: rotate(0deg);
        }
    25%{
        transform: rotate(90deg);
        }
    50%{
        transform: rotate(180deg);
        }
    75%{
        transform: rotate(270deg);
        }
    100%{
        transform: rotate(360deg);
        }
}

@keyframes rotacion{
    0%{
        transform: rotate(0deg);
        }
    25%{
        transform: rotate(90deg);
        }
    50%{
        transform: rotate(180deg);
        }
    75%{
        transform: rotate(270deg);
        }
    100%{
        transform: rotate(360deg);
        }
}

.contenedor2 .carga{
    display: block;
    -webkit-animation: rotacion 4s infinite 0s linear;
            animation: rotacion 4s infinite 0s linear;
    width: 80px;
    height: 80px;

}

.generando2{
    display: block;
    width: 100%;
}

.cargando2{
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 60vh;
}

.contenedor2 p {
    padding: 0px;
    margin: 0px;
  }

