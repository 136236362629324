/****************CONFIGURACION-BASICA************************/
html,body,#root{
    height: 100%;
    width: 100%;
    margin:0;
    padding:0;
}
.swal2-container {
    z-index: 30000!important;
}

.swal2-container {
    display: -webkit-box;
    display: flex;
    position: fixed;
    z-index: 30000!important;
}

body {
    /* background: linear-gradient(135deg, #6ec575 0, #3b8686 100%); */
    /* background-repeat: repeat;
    background: url("./componentesGenericos/assets/Img/Fondo.png") repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
    background-size: 150px;
  background-repeat: round;
  background-image:
    url("./componentesGenericos/assets/Img/fondo.png");
}

.boton {
    margin: "10px";
    padding: "20px";
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


/****************ALERTAS************************/
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  .alert {
    border: 0;
    border-radius: 0;
    padding: 20px 15px;
    line-height: 20px;
  }

  .alert.alert-primary {
    background-color: #a72abd;
    color: #ffffff;
  }
  .alert.alert-primary a,
  .alert.alert-primary .alert-link {
    color: #ffffff;
  }
  .alert.alert-success {
    background-color: #55b559;
    color: #ffffff;
  }
  .alert.alert-success a,
  .alert.alert-success .alert-link {
    color: #ffffff;
  }
  .alert.alert-info {
    background-color: #00cae3;
    color: #ffffff;
  }
  .alert.alert-info a,
  .alert.alert-info .alert-link {
    color: #ffffff;
  }
  .alert.alert-warning {
    background-color: #ff9e0f;
    color: #ffffff;
  }
  .alert.alert-warning a,
  .alert.alert-warning .alert-link {
    color: #ffffff;
  }
  .alert.alert-danger {
    background-color: #f55145;
    color: #ffffff;
  }
  .alert.alert-danger a,
  .alert.alert-danger .alert-link {
    color: #ffffff;
  }
  .alert.alert-rose {
    background-color: #ea2c6d;
    color: #ffffff;
  }
  .alert.alert-rose a,
  .alert.alert-rose .alert-link {
    color: #ffffff;
  }
  .alert-info,
  .alert-danger,
  .alert-warning,
  .alert-success {
    color: #ffffff;
  }

  .alert-dismissible {
    padding-right: 4rem;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
/****************HELP************************/
  .w100{
      width: 100%!important;
  }

/******************CARUSEL****************************/

.carousel-root{
	height: 100%!important;
	max-width: 100%!important;
}
.carousel.carousel-slider{
	height: 100%!important;
    max-width: 100%!important;
}
.carousel .slider-wrapper{
	height: 100%!important;
	max-width: 100%!important;
}
.carousel .slider.animated{
	height: 100%!important;
	max-width: 100%!important;
}
.slide{
	height: 100%!important;
	max-width: 100%!important;
}
.carousel .slider-wrapper.axis-horizontal .slider{
    height: 100%!important;
	max-width: 100%!important;
}

.maximizaAlto{
    height: 100%!important;
    max-width: 100%!important;
    cursor: pointer;
}

.maximizaAltoImagen{
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.cardMovil{
    height: 100%;
    border: 2px solid rgba(0, 123, 255,0.33);
    border-radius: 9px;
}
.cardContent{
    height: 100%;
    width: 100%;
    padding: 0px;
}

.imagenContentMovil{
width: auto;
height: 100%;
max-Height:100px;
}

/*BOTON DE LOGIN*/


.MuiButton-contained.Mui-disabled{
background:white!important;
}
.MuiTableCell-body{
color: transparent;
}
.colorFila{
    color:red!important;
}
.colorNegro{
    color:black;
}
