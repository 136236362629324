/* fonts  */
@import url("https://fonts.googleapis.com/css?family=Abel|Aguafina+Script|Artifika|Athiti|Condiment|Dosis|Droid+Serif|Farsan|Gurajada|Josefin+Sans|Lato|Lora|Merriweather|Noto+Serif|Open+Sans+Condensed:300|Playfair+Display|Rasa|Sahitya|Share+Tech|Text+Me+One|Titillium+Web");

#td-container {
    box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
    background: rgba(255, 255, 255, 0.9);
    text-align: left;
    border-radius: 1px;
    border: 2px 2px solid black;
    overflow: hidden;
    margin: 0em auto;
    position: relative;
    max-height: 1280px;
    height: 100%;
    max-width: 507px;
    width: 100%;
}

#td-container img {
    width: 100%;
    height: 100%;
}

.td-product-details {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    float:none
}
/* Nombre del paciente */
.td-information {
    position: absolute;
    top: 42%;
    left: 3%;
    /* width: auto;
    height: auto; */
    color: black;
    font-size: 23px;

}
/* Imagen del QR */
.td-product-qr {
    position: absolute;
    top: 2.7%;
    left: 5.5%;
    height: 22.9%;
    width: 22.9%;
    text-align: center;
    font-weight: bold;
    font-size: 5vw;
    color: white;
    font-family: arial;
}
/* Sin imagen */

#td-container {
    box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
    background: rgba(255, 255, 255, 0.9);
    text-align: left;
    border-radius: 1px;
    border: 2px 2px solid black;
    overflow: hidden;
    margin: 0em auto;
    position: relative;
    height: 100%;
    width: 100%;
}

.td-product-details {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    float:none
}
/* Imagen del QR - Sin imagen*/
.td-product-qr-2 {
    position: relative;
    top: 4%;
    left: 15%;
    height:auto;
    width: 70%;
    text-align: center;
    font-weight: bold;
    font-size: 5vw;
    color: white;
    font-family: arial;
}
/* Información del paciente - sin imagen*/
.td-information-2 {
    position: relative;
    top: 42%;
    /* left: 3%; */
    width: auto;
    height: auto;
    color: black;
    font-size: 23px;

}

@media (max-width: 1700px) and (max-height: 1200px){
    #td-container {
        box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
        background: rgba(255, 255, 255, 0.9);
        text-align: left;
        border-radius: 1px;
        border: 2px 2px solid black;
        overflow: hidden;
        margin: 0em auto;
        position: relative;
        max-height: 1200px;
        height: 100%;
        max-width: 600px;
        width: 27%;
    }

    #td-container img {
        width: 100%;
        height: 100%;
    }

    .td-product-details {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        float:none
    }
    /* Nombre del paciente */
    .td-information {
        position: absolute;
        top: 42.3%;
        left: 1%;
        width: auto;
        height: auto;
        color: black;
        font-size: 15px;
        text-align: center;
    }
    /* Imagen del QR */
    .td-product-qr {
        position: absolute;
        top: 2.7%;
        left: 5%;
        height: 23.5%;
        width: 23.5%;
        text-align: center;
        font-weight: bold;
        font-size: 5vw;
        color: white;
        font-family: arial;
    }
    }


@media (max-width: 1300px) and (max-height: 700px){
    #td-container {
        box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
        background: rgba(255, 255, 255, 0.9);
        text-align: left;
        border-radius: 1px;
        border: 2px 2px solid black;
        overflow: hidden;
        margin: 0em auto;
        position: relative;
        max-height: 1200px;
        height: 100%;
        max-width: 600px;
        width: 35%;
    }

    #td-container img {
        width: 100%;
        height: 100%;
    }

    .td-product-details {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        float:none
    }
    /* Nombre del paciente */
    .td-information {
        position: absolute;
        top: 42.3%;
        left: 2%;
        width: auto;
        height: auto;
        color: black;
        font-size: 15px;
    }
    /* Imagen del QR */
    .td-product-qr {
        position: absolute;
        top: 2.7%;
        left: 5%;
        height: 23.5%;
        width: 23.5%;
        text-align: center;
        font-weight: bold;
        font-size: 5vw;
        color: white;
        font-family: arial;
    }
    }


/* Basada para iPad Pro */
@media (max-width: 1024px) and (max-height: 1366px){
    #td-container {
        box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
        background: rgba(255, 255, 255, 0.9);
        text-align: left;
        border-radius: 1px;
        border: 2px 2px solid black;
        overflow: hidden;
        margin: 1em auto;
        position: relative;
        max-height: 1300px;
        height: 100%;
        max-width: 740px;
        width: 100%;
    }

    #td-container img {
        width: 100%;
        height: 100%;
    }

    .td-product-details {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        float:none
    }
    /* Nombre del paciente */
    .td-information {
        position: absolute;
        top: 42.5%;
        left: 2%;
        width: auto;
        height: auto;
        color: black;
        font-size: 28px;
    }
    /* Imagen del QR */
    .td-product-qr {
        position: absolute;
        top: 2.7%;
        left: 5.5%;
        height: 23%;
        width: 23%;
        text-align: center;
        font-weight: bold;
        font-size: 5vw;
        color: white;
        font-family: arial;
    }
    }

/* Basada para iPad */
@media (max-width: 800px) and (max-height: 1024px){
    #td-container {
        box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
        background: rgba(255, 255, 255, 0.9);
        text-align: left;
        border-radius: 1px;
        border: 2px 2px solid black;
        overflow: hidden;
        margin: 1em auto;
        position: relative;
        max-height: 1000px;
        height: 100%;
        max-width: 610px;
        width: 100%;
    }

    #td-container img {
        width: 100%;
        height: 100%;
    }

    .td-product-details {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        float:none
    }
    /* Nombre del paciente */
    .td-information {
        position: absolute;
        top: 42.5%;
        left: 2%;
        width: auto;
        height: auto;
        color: black;
        font-size: 20px;
    }
    /* Imagen del QR */
    .td-product-qr {
        position: absolute;
        top: 2.7%;
        left: 5.5%;
        height: 23%;
        width: 23%;
        text-align: center;
        font-weight: bold;
        font-size: 5vw;
        color: white;
        font-family: arial;
    }
    }

@media (max-width: 400px) and (max-height: 650px){
#td-container {
    box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
    background: rgba(255, 255, 255, 0.9);
    text-align: left;
    border-radius: 1px;
    border: 2px 2px solid black;
    overflow: hidden;
    margin: 0em auto;
    position: relative;
    max-height: 640px;
    height: 100%;
    max-width: 380px;
    width: 100%;
}

#td-container img {
    width: 100%;
    height: 100%;
}

.td-product-details {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    float:none
}
/* Nombre del paciente */
.td-information {
    position: absolute;
    top: 42.5%;
    left: 2%;
    width: auto;
    height: auto;
    color: black;
    font-size: 16px;
}
/* Imagen del QR */
.td-product-qr {
    position: absolute;
    top: 2.7%;
    left: 5.5%;
    height: 23%;
    width: 23%;
    text-align: center;
    font-weight: bold;
    font-size: 5vw;
    color: white;
    font-family: arial;
}
}

/* Basada para iPhone 4 */
@media (max-width: 321px) and (max-height: 480px){
    #td-container {
        box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
        background: rgba(255, 255, 255, 0.9);
        text-align: left;
        border-radius: 1px;
        border: 2px 2px solid black;
        overflow: hidden;
        margin: 0em auto;
        position: relative;
        max-height: 470px;
        height: 100%;
        max-width: 310px;
        width: 100%;
    }

    #td-container img {
        width: 100%;
        height: 100%;
    }

    .td-product-details {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        float:none
    }
    /* Nombre del paciente */
    .td-information {
        position: absolute;
        top: 42%;
        left: 2%;
        width: auto;
        height: auto;
        color: black;
        font-size: 12px;
    }
    /* Imagen del QR */
    .td-product-qr {
        position: absolute;
        top: 2.7%;
        left: 5.5%;
        height: 23%;
        width: 23%;
        text-align: center;
        font-weight: bold;
        font-size: 5vw;
        color: white;
        font-family: arial;
    }
}

/* Basada para Pixel 2/XL */
@media (max-width: 420px) and (max-height: 830px){
    #td-container {
        box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
        background: rgba(255, 255, 255, 0.9);
        text-align: left;
        border-radius: 1px;
        border: 2px 2px solid black;
        overflow: hidden;
        margin: 0em auto;
        position: relative;
        max-height: 700px;
        height: 100%;
        max-width: 400px;
        width: 100%;
    }

    #td-container img {
        width: 100%;
        height: 100%;
    }

    .td-product-details {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        float:none
    }
    /* Nombre del paciente */
    .td-information {
        position: absolute;
        top: 42.3%;
        left: 2%;
        width: auto;
        height: auto;
        color: black;
        font-size: 16px;
    }
    /* Imagen del QR */
    .td-product-qr {
        position: absolute;
        top: 2.7%;
        left: 5.5%;
        height: 23%;
        width: 23%;
        text-align: center;
        font-weight: bold;
        font-size: 5vw;
        color: white;
        font-family: arial;
    }
}
