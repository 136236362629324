.ticket2 * {
    font-size: 15px;
    font-family: 'Times New Roman';
}


.ticket2 {
    display: none;
    /* width: 220px; */
    max-width: 100%;
}

/* .contenedor2 div.cargando2 {
    display:none;
} */

.ticket2 img {
    max-width: 90px;
    min-width: 80px !important;
}

.ticket2 .logo{
    margin: 0 auto;
    text-align: center;
}

.ticket2 .contenedor{
    border-top-style: double;
    border-bottom-style: double;
    padding: 0px 0;
    margin: 10px 0;
    font-size: 16px;
}

.ticket2 .contenedor2{
    border-top-style: double;
    border-bottom-style: double;
    padding: 0px 0;
    margin: 10px 0;
    font-size: 16px;
}

.ticket2 .titulo{
    font-size: 17px;
}


.ticket2 .tablaproductos, .ticket2 .tablaproductos td,.ticket2 .tablaproductos th,.ticket2 .tablaproductos tr {
    border-top: 0px solid black;
    border-collapse: collapse;
    font-size: 12px;
}

.ticket2 .tablaproductos{
    margin:0 auto;
    text-align: left;
    width: 100%;
}

.ticket2 .tablaproductos th{
    font-size: 9px;
}

.ticket2 .tablaproductos td.cantidad, .ticket2 .tablaproductos th.cantidad {
    width: 14%;
    max-width: 14%;
    word-break: break-all;
    text-align:right;
}

.ticket2 td.codigo {
    width: 10%;
    max-width: 10%;
    text-align: left;
    padding-left: 3px;
    font-size: 9px;
}

.ticket2 th.codigo {
    width: 10%;
    max-width: 10%;
    text-align: left;
    padding-left: 3px;
}

.ticket2 td.producto,.ticket2 th.producto {
    width: 30%;
    max-width: 30%;
    text-align: left;
    padding-left: 3px;
}

.ticket2 td.precio {
    width: 20%;
    max-width: 20%;
    word-break: break-all;
     text-align:right;

}

.ticket2 th.precio {
    width: 20%;
    max-width: 20%;
    word-break: break-all;
     text-align:center;

}

.ticket2 td.totalimporte {
    width: 24%;
    max-width: 24%;
    word-break: break-all;
     text-align:right;
}

.ticket2 th.totalimporte {
    width: 24%;
    max-width: 24%;
    word-break: break-all;
     text-align:center;
}


.ticket2 td.espacio,.ticket2 th.espacio {
    width: 0%;
    max-width: 1%;
}

.ticket2 .ahorro{
    text-align: center;
    font-weight: lighter;
}

.ticket2 .totales{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
  justify-content:flex-end;
  font-size: 12px;
}

.ticket2 .tablaTotal{
    font-size: 12px;
}

.ticket2 .totaltxt{
    text-align: right;
    font-weight: bold;
    font-size: 12px;
}

.ticket2 .derecha{
    text-align: right;
    font-weight: bold;
    font-size: 12px;
}

.ticket2 .izquierda{
    text-align: left;
    font-weight: bold;
    font-size: 12px;
}

.ticket2 .total{
    text-align: left;
    font-weight: bold;
}

.ticket2 .centrado {
    text-align: center;
    align-content: center;
}

.ticket2 .pie{
    text-align: right;
    width: 98%;
}

.contenedor2 .ticket2 .garantias{
    border-top: 0;
    width:100%;
    text-align: center;
}

@media print {
    .contenedor2 .cargando2,
    .contenedor2 .cargando2 * {
        display: none !important;
    }

    .contenedor2 .ticket2{
        display: inherit !important;
        width:100%;
    }


    .noImprimir {
        display: none !important;
    }
}

@keyframes rotacion{
    0%{
        transform: rotate(0deg);
        }
    25%{
        transform: rotate(90deg);
        }
    50%{
        transform: rotate(180deg);
        }
    75%{
        transform: rotate(270deg);
        }
    100%{
        transform: rotate(360deg);
        }
}

.contenedor2 .carga{
    display: block;
    animation: rotacion 4s infinite 0s linear;
    width: 80px;
    height: 80px;

}

.generando2{
    display: block;
    width: 100%;
}

.cargando2{
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 60vh;
}

.contenedor2 p {
    padding: 0px;
    margin: 0px;
  }
